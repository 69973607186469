<template>
  <el-card class="box-card">
    <div
      slot="header"
      style="
        display: flex;
        justify-content: space-between;
        padding: 0 50px 0 10px;
      "
      class="clearfix"
    >
      <h3 class="inBlock">创建直播间</h3>
      <back></back>
    </div>
    <div class="box">
      <div class="liveitem">
        <div class="content">
          <el-form
            :model="from"
            :rules="rules"
            ref="liveFrom"
            label-width="100px"
          >
            <el-form-item label="主播姓名" prop="user_id">
              <el-select v-model="from.user_id" :placeholder="anchorName">
                <el-option
                  v-for="item in livePeopleList"
                  :key="item.id"
                  :label="item.username + '   ' + item.mobile"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="直播封面图" style="margin-top: 70px">
              <div class="imgbox">
                <div
                  v-for="(item, index) in imagesary"
                  :key="index"
                  class="imgItem"
                >
                  <span class="shou" @click="delImg(item)">×</span>
                  <img class="avatar" :src="imgurl + item" alt="" />
                </div>
                <up-img @addimg="upDataimg" :type="'goods'"></up-img>
              </div>
            </el-form-item>
            <el-form-item label="直播详情图">
              <div class="imgbox">
                <div class="imgItem" v-if="from.detail_cover">
                  <span class="shou" @click="deldetail_cover">×</span>
                  <img class="avatar" :src="imgurl + from.detail_cover" alt="" />
                </div>
                <up-img v-else @addimg="updetail_cover" :type="'goods'"></up-img>
              </div>
            </el-form-item>
            <el-form-item label="直播分类" prop="cate_id">
              <el-select v-model="from.cate_id" :placeholder="liveType">
                <el-option
                  v-for="item in liveTypelist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
                
              </el-select>
            </el-form-item>
            <el-form-item label="直播名称" prop="title">
              <el-input
                placeholder="请输入直播名称"
                v-model="from.title"
              ></el-input>
            </el-form-item>
            <el-form-item label="直播状态">
              <el-radio v-model="from.status" :label="1">未直播</el-radio>
              <el-radio v-model="from.status" :label="2">直播中</el-radio>
              <el-radio v-model="from.status" :label="3">已关闭</el-radio>
            </el-form-item>
            <el-form-item label="选择直播方式">
              <el-radio v-model="from.orientation" :label="2">横屏</el-radio>
              <el-radio v-model="from.orientation" :label="1">竖屏</el-radio>
            </el-form-item>
            <el-form-item label="选择商品">
              <el-button plain size="small" @click="chooseProduct"
                >点击选择</el-button
              >
              <!-- {{from.goods_ids}} -->
              <div class="selectedProList">
                <div
                  class="selectedProItem"
                  v-for="(item2, index2) in selectProList"
                  :key="index2"
                >
                  <img :src="imgurl + item2.cover_pic" alt="" srcset="" />
                  <div class="probottom">
                    <p>{{ item2.name }}</p>
                    <span>{{ item2.price }}</span>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="直播开始时间">
              <el-date-picker
                v-model="from.appointment_time"
                type="datetime"
                placeholder="选择直播时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div class="submit">
            <span class="shou" @click="submit">确定</span>
            <span class="shou" @click="$router.go(-1)">返回</span>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="选择商品" :visible.sync="chooseprodialog" width="40%">
      <div class="productList">
        <div
          class="selectedProduct"
          v-for="(item, index) in multipleSelection"
          :key="index"
        >
        </div>
        <div class="tiaojian">
          <div class="left">
            <strong>商品名称: </strong
            ><el-input
              v-model="livproForm.name"
              placeholder="请输入商品名称"
            ></el-input>
          </div>
        <span class="shaixuan shou" @click="filterListModel">筛选</span>
      </div>
        <el-table
          row-key="id"
          ref="multipleTable"
          :data="liveProductList"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="setCheckout"
          @select='test'
        >
          <el-table-column reserve-selection type="selection" width="55">
          </el-table-column>
          <el-table-column label="商品ID" width="120">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称" width="300">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="商品价格"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.price }}</template>
          </el-table-column>
        </el-table>
        <!-- <el-button @click="toggleSelection(data)">切换第二、第三行的选中状态</el-button> -->
         <el-pagination
          background
          style="margin-top: 20px"
          @current-change="crtChg"
          :page-size="livproForm.limit"
          :current-page="livproForm.page"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="chooseprodialog = false">取 消</el-button>
        <el-button type="primary" @click="chooseProSele">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>
<script>
import UpImg from "@/components/upImg.vue";
import { getSystemsettings } from "../../api/getSystemsetting.js";
import {
  findLiveCateByLiveroom,
  findLivePeopleByLiveroom,
  findLiveProductByLiveroom,
  submitCreateLiveRoom,
  findLiveRoomDetail,
} from "@/api/api.js";
export default {
  components: { UpImg },
  name: "addliveroom",
  data() {
    return {
      imagesary: [],
      liveTypelist: [],
      liveType: "请选择直播分类",
      anchorName: "请选择主播",
      livePeopleList: [],
      appointment_time: "",
      chooseprodialog: false,
      liveProductList: [],
      multipleSelection: [],
      multipleSelection2: [],
      selectProList: [],
      selectProListAll: [],
      imgurl: "",
      data: [],
      total: 0,
      type: 1,
      from: {
        user_id: "", // 主播id
        title: "", // 直播间名称
        status: 1, // 直播状态
        orientation: 1, // 直播方式
        cover: "", // 封面图片
        cate_id: "", // 直播分类id
        appointment_time: "", // 直播开始时间
        goods_ids: [],
        live_type: 2,
        id: "",
        detail_cover:''    // 详情页图片
      },
      rules: {
        name: [
          { required: true, message: "请填写门店名称", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请填写联系方式", trigger: "blur" }],
      },
      livproForm: {
        limit: 7,
        page: 1,
        name: ''
      },
      shopList: [],
      oldList:[]
    };
  },
  methods: {
    test(selection,row){
      console.log('selection',selection);
      console.log('row',row);
      // 商品列表id
      if(this.from.goods_ids.length == 0){
        this.from.goods_ids.push(row.id)
      }else{
        this.from.goods_ids.forEach((item, i) => {
          if(item == row.id){
            this.from.goods_ids.splice(i, 1)
          }else{
            if(i == this.from.goods_ids.length - 1){
              this.from.goods_ids.push(row.id)
            }
          }
        })
      }
      // 商品列表数据
      if(this.selectProList.length == 0){
         this.selectProList.push(row)
      }else{
        this.selectProList.forEach((item, i) => {
          if(item.id == row.id){
            this.selectProList.splice(i, 1)
          }else{
            if(i == this.selectProList.length - 1){
              this.selectProList.push(row)
              //  console.log()
            }
          }
        })

      }
      // this.from.goods_ids = this.from.goods_ids
      

      console.log(this.from.goods_ids)
      console.log( this.selectProList)
    },  

    updetail_cover(img) {
      this.from.detail_cover = img
    },
    deldetail_cover() {
      this.from.detail_cover = ''
    },

    // 表格选中项
    setCheckout(e) {
      console.log(e)
      console.log('all',this.from.goods_ids);
      // this.from.goods_ids.push(e.id)

      let ary = [];
      e.forEach((item) => {
        this.liveProductList.forEach((item2) => {
          if (item2.id == item.id) {
            ary.push(item);
          }
        });
      });
      this.selectProListAll[this.livproForm.page] = ary;
    },
    // 切换分页执行
    tableDefaultSelect() {
      this.type = 2;
      let rows = []; //先定义一个选中行的数组
      if (JSON.stringify(this.selectProListAll) !== "[]") {
        //先判定是否有值
        let arr = this.selectProListAll.flat(); //将数组进行降维打击
        for (let i in arr) {
          //ES6新出的findIndex，返回符合条件的索引位置，有则返回，无则返回-1，唯一标识id请根据实际修改
          if (this.liveProductList.findIndex((x) => x.id === arr[i].id) >= 0) {
            rows.push(
              this.liveProductList.findIndex((x) => x.id === arr[i].id)
            );
          }
        }
        this.$nextTick(() => {
          //等dom更新之后再选中，并且表格一定要设置ref
          if (rows) {
            rows.forEach((row) => {
              this.$refs.multipleTable.toggleRowSelection(
                this.liveProductList[row],
                true
              );
            });
          }
        });
      }
    },
    delImg(item) {
      let index = this.imagesary.indexOf(item);
      this.imagesary.splice(index, 1);
    },
    upDataimg(e) {
      if (this.imagesary.length >= 1) {
        this.imagesary.shift();
      }
      this.imagesary.push(e);
    },

    // 获取直播分类
    async getTypelist() {
      const { data } = await findLiveCateByLiveroom();
      if (data.code != 200) return this.$message.error(data.data);
      this.liveTypelist = data.data;
    },

    // 获取直播人员列表
    async getPeoplelist() {
      const { data } = await findLivePeopleByLiveroom();
      if (data.code != 200) return this.$message.error(data.data);
      this.livePeopleList = data.data;
    },
    // 切换分页
    crtChg(e) {
      this.livproForm.page = e;
      this.getProductlist();
      this.tableDefaultSelect();
    },
    // 选择商品按钮
    chooseProduct() {
      this.chooseprodialog = true;
      this.getProductlist();
    },
    // 商品列表确定
    chooseProSele() {
        // console.log(' this.selectProListAll------------',  this.selectProListAll)
        // this.selectProList = this.selectProListAll.flat();
        // this.from.goods_ids = this.selectProList.map((item) => {
        //   return item.id;
        // });
      
      console.log( 'this.from.goods_ids', this.from.goods_ids)
      this.chooseprodialog = false;
    },

    // 获取直播间详情
    async getLiveRoomDetail(id) {
      const { data } = await findLiveRoomDetail(id);
      if (data.code != 200) return false;
      let from = data.data;
      this.from.id = from.live_log.id;
      this.from.title = from.live_log.title;
      this.from.status = from.live_log.status;
      this.from.orientation = from.live_log.orientation;
      this.from.detail_cover = from.live_log.detail_cover
      this.from.goods_ids = from.live_log.goods_ids.split(",");
      console.log('this.from.goods_ids=====111',this.from.goods_ids)
      this.from.user_id = from.live_log.user_id;
      this.from.cate_id = from.live_log.cate_id;
      this.from.appointment_time = this.$timeTo.time3(
        from.live_log.appointment_time
      );
      this.selectProList = from.goods;
      this.imagesary = from.live_log.cover.split(",");
      this.liveTypelist = from.live_log.cate_name.split(",");
    },
    // 获取直播商品列表
    async getProductlist() {
      this.livproForm.name = ''
      const { data } = await findLiveProductByLiveroom(this.livproForm);
      if (data.code != 200) return this.$message.error(data.data);
      this.liveProductList = data.data.data;

      this.total = data.data.total;
      // 获取后渲染分页选中项
      this.$nextTick(() => {
        this.selectProList.forEach((item) => {
          this.liveProductList.forEach((item2) => {
            if (item.id == item2.id) {
              console.log(item.id);
              this.$refs['multipleTable'].toggleRowSelection(item2, true);
            }
          });
        });
      });
      console.log(this.selectProList)
    },
     // 获取直播商品列表搜索
    async filterListModel(){
      this.livproForm.page = 1
      const { data } = await findLiveProductByLiveroom(this.livproForm);
            if (data.code != 200) return this.$message.error(data.data);
            this.liveProductList = data.data.data;
            this.total = data.data.total;
            this.livproForm.name = ''
            // 获取后渲染分页选中项
            this.$nextTick(() => {
              this.selectProList.forEach((item) => {
                this.liveProductList.forEach((item2) => {
                  if (item.id == item2.id) {
                    console.log(item.id);
                    this.$refs['multipleTable'].toggleRowSelection(item2, true);
                  }
                });
              });
            });
      console.log(this.selectProList)
    },
    // 提交
    submit() {
      let from = this.from;
      this.imagesary.forEach((item) => {
        from.cover = item.toString();
      });
      from.appointment_time = this.$timeTo.time3(
        this.$timeTo.time4(from.appointment_time)
      );
      this.$refs["liveFrom"].validate(async (valid) => {
        if (valid) {
          if (
            from.user_id == "" ||
            from.title == "" ||
            from.cover == "" ||
            from.cate_id == "" ||
            from.goods_ids == "" ||
            from.appointment_time == "" ||
            from.status == ""
          )
          
          return this.$message.warning("提交失败, 请检查内容是否填写正确!");
          const { data } = await submitCreateLiveRoom(from);
          if (data.code != 200)
          return this.$message.error("提交错误, 请稍后重试!");
          this.$message.success("提交成功");
          this.$router.push("/live/room");
        } else {
          this.$message.warning("提交失败, 请检查内容是否填写正确!!!");
          return false;
        }
      });
    },
  },
  created() {
    this.getTypelist();
    this.getPeoplelist();

    if (this.$route.query.id) {
      this.getLiveRoomDetail({ id: this.$route.query.id });
    }
    getSystemsettings.then((res) => {
      this.imgurl = res;
    });
  },
};
</script>
<style lang="less" scope>
.box {
  .liveitem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .label {
      flex: 1;
      border: 1px solid #eee;
      text-align: center;
      padding-top: 40px;
      font-weight: 900;
      font-size: 16px;
      margin-right: 10px;
    }
    .content {
      flex: 8;
      border: 1px solid #eeeeee;
      padding: 20px;
      .el-form {
        .el-form-item {
          .el-dropdown {
            span {
              border: 1px solid #c0c4cc !important;
              color: #777;
            }
          }
          .imgbox {
            display: flex;
            .imgItem {
              width: 178px;
              height: 178px;
              padding: 10px;
              margin-left: 10px;
              border-radius: 5px;
              position: relative;
              border: 1px solid #eee;
              span {
                position: absolute;
                background-color: #ffd1d1;
                top: 0;
                right: 0;
                height: 20px;
                text-align: center;
                line-height: 20px;
                width: 20px;
                z-index: 100;
                color: aliceblue;
                &:hover {
                  background-color: #ff4544;
                }
              }
            }
            .avatar {
              width: 100%;
              height: 100%;
            }
          }
          .el-dropdown {
            span {
              padding: 5px 10px;
              border: 1px solid #333;
            }
          }
          .el-input {
            width: 375px;
          }
        }
      }
      .selectedProList {
        display: flex;
        flex-wrap: wrap;
        .selectedProItem {
          width: 100px;
          height: 175px;
          border: 1px solid #efefef;
          margin-right: 10px;
          img {
            width: 98px;
            height: 98px;
          }
          .probottom {
            padding: 6px;
            p {
              line-height: 20px;
              font-size: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 86px;
            }
            span {
              color: #ff4544;
            }
          }
        }
      }
    }
  }
}
.selectedProduct p {
  line-height: 30px;
}
.el-table /deep/td {
  padding: 10px 0;
}
.submit {
  padding-top: 20px;
  border-top: 1px solid #eee;
  margin-bottom: 50px;
  span {
    background-color: #19c2ff;
    padding: 5px 10px;
    margin-right: 10px;
    color: #fff;
    &:last-child {
      background-color: #1bc6a3;
    }
  }
}
.tiaojian {
  margin: 20px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #edeff0;
  position: relative;
  .left {
    strong {
      font-weight: normal;
      font-size: 14px;
      color: #606266;
      padding-top: 10px;
      margin-right: 2px;
    }
    .el-input {
      width: 300px;
      margin-right: 20px;
      /deep/.el-input__inner {
        height: 30px;
      }
    }
    .checkboxBox {
      padding-top: 10px;
      margin: 0 0 20px 0;
    }
  }
  .shaixuan {
    background-color: #18bdff;
    color: aliceblue;
    padding: 3px 10px;
    border-radius: 2px;
    position: absolute;
    bottom: 15px;
    left: 400px;
    &:hover {
      background-color: #1abe9c;
    }
  }
}
</style>